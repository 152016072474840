import React from 'react'
import { PageLayout, ContactForm, ContainerList } from '../../components'

const DocsIndexPage = ({ location }) => {
  return (
    <PageLayout 
      location={location}
      title="Request a Demo"
      description="Simple, powerful, automated data integration platfrom for corporate finance and governance teams."
      pageTheme="dark"
      header={{
        menu: {
          actions: false
        }
      }}
      pageHero={{
        section: { 
          margin: { bottom: "10vh", horizontal: "11vw" },
        },
        main: {
          heading: {
            label: "Take control of your data; attain your business goals.",
          },
          subHeading: {
            label: "An easy and reliable way to collect enterprise data, delivered to your data warehouse of choice for comprehensive business analytics.",
            margin: {
              bottom: "xlarge"
            }
          },
          content: <Content />,
          style: { flex: "0 1 60%" }
        },
        aside: {
          content: <ContactForm border={{ color: "brand" }} />,
          style: { flex: "0 1 40%" }
        }
      }}
    />
  )
}

const Content = () => {
  return (
    <ContainerList
      section={{
        pad: "0",
        margin: { top: "medium" }
      }}
      pad="0"
      margin="0"
      fontSize="medium"
      condensed={true}
      items={[
        {
          title: "No-code - Simple and Agile",
          description: "Add and remove data sources without engineering support to quickly react to changing business requirements.",
          basis: "full",
          pad: "0",
          image: {
            src: "TrackField",
            position: "left",
            justify: "start",
            size: "36px",
            theme: "blue"
          }
        },
        {
          title: "Drastically reduce your current data operations",
          description: "We manage everything so you can focus on uncovering insights instead of managing technology stacks.",
          basis: "full",
          pad: "0",
          image: {
            src: "CircleHalfShade",
            position: "left",
            justify: "start",
            size: "36px",
            theme: "blue"
          }
        },
        {
          title: "Built for future of data",
          description: "Cloud-native and instantly scalable with support modern cloud data warehouse solutions.",
          basis: "full",
          margin: "0",
          pad: "0",
          image: {
            src: "Motherboard",
            position: "left",
            justify: "start",
            size: "36px",
            theme: "blue"
          }
        }
      ]}
    />
  )
}

export default DocsIndexPage